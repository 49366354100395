@keyframes yukari {
  50%{
    transform: translateY(-10px);
  }
}

@keyframes bulutlar {
  50%{
    transform: translateX(20px);
  }
}

@keyframes hareketliBulut {
  0%{
    opacity: 0;
    transform: translateX(-100px);
  }
  90%{
    transform: translateX(200px);
    opacity: 1;
  }
  100%{
    transform: translateX(210px);
    opacity: 0;
  }
}