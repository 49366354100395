.container.first{
  padding-top: 40px;
  .header{
    /*display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;*/
    .menu{
      font-size: 12px;
      font-weight: 600;
      ul{
        display: inline-block;
        margin: 0;
        padding: 0;
        justify-content: flex-start;
        display: flex;
        @media screen and (max-width: 575px){
          justify-content: center;
          display: flex;
        }
        li{
          display: inline-block;
          margin-right: 16px;
          a{
            color: $siyah;
            &:hover{
              color: $siyah;
              text-decoration: none;
            }
          }
          &:last-child{
            margin-right: 0px;
          }
        }
      }
    }
  }
  section.hello{
    margin-top: 75px;
    display: flex;
    justify-content: space-between;
    .bulutlar{
      z-index: -99999;
      position: absolute;
      margin-top: -200px;
      opacity: 0.8;
      animation: 30s ease-in-out normal none infinite bulutlar;
    }
    .hareketliBulut{
      padding-left: 200px;
      z-index: 2;
      position: absolute;
      margin-top: 200px;
      animation: hareketliBulut 35s infinite;
      img{
        margin-left: 220px;
        width: 500px;
      }
    }
    .text{
      z-index: 3;
      flex-grow: 1;
      margin-top: 80px;
      .profil{
        width: 80px;
        margin-bottom: 20px;
      }
      h1.merhaba{
        font-size: 26px;
        margin: 0;
        padding: 0;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .info{
        z-index: 3;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 60px;
        strong{
          text-decoration: underline;
        }
      }
      h1.kariyer{
        font-size: 24px;
        margin: 0;
        padding: 0;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .kariyerTitle{
        font-size: 16px;
        font-weight: 700;
      }
      .kariyerDate{
        font-size: 12px;
      }
      .kariyerInfo{
        font-size: 12px;
        margin-top: 10px;
        color: $yeni;
      }
    }
    .hero{
      z-index: 3;
      padding-left: 30px;
      animation: 3s ease-in-out 00s normal none infinite yukari;
    }
    @media screen and (max-width: 575px) {
      .hero {
          display: none;
      }
      .bulutlar{
        display: none;
      }
      .hareketliBulut{
        display: none;
      }
      .text{
        text-align: center;
        margin-top: 0px;
      }
      .kariyerInfo{
        margin-top: 5px !important;
        margin-bottom: 40px;
      }
    }
  }
}